$spacer: 2rem;
$body-bg: #fff;
$body-color: #2d3944;

$light-blue: #cdd6dd;
$blue: #385b74;

$primary: #c61531;
$secondary: #2d3944;
$success: #05c322;
$danger: #af0000;
$warning: #f78e00;
$light: #d6dadd;

$border-color: #f1f1f4;

$shadow: rgba($light, 0.2);
$box-shadow: 0 10px 20px $shadow;

$alert-bg-level: 0;

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
  xl: 1600px,
);

// Fonts
$font-family-sans-serif: 'Open Sans',
'Helvetica Neue',
Helvetica,
Arial,
sans-serif;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700;
$headings-color: $secondary;

$app-sidebar-width: 320px;

$box-radius: 15px;