.input-group {
  .react-datepicker-wrapper{
    position: relative;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
  }
  .clear-date{
    position: absolute;
    right: 30px;
    top: 6px;
    svg {
      display: inline-block;
      fill: hsl(0,0%,80%);;
      line-height: 1;
      stroke: hsl(0,0%,80%);;
      stroke-width: 0;
    }
  }
}

label {
  font-weight: 700;
  font-size: 15px;
}

.form-control {
  border: 1px solid $light-blue;

  .dropdown {
    width: 100%;

    button {
      width: 100%;
      padding: 0;
      margin-left: 0;
      text-align: left;
      &::after {
        float: right;
      }
    }
    .dropdown-menu {
      width: 100%;
    }
  }
}

.form-check {
  input,
  label {
    cursor: pointer !important;
  }
}

// Readonly mode
input[type="text"]:disabled,
input[type="number"]:disabled,
textarea:disabled,
.input-disabled,
.select__control--is-disabled {
  background: transparent !important;
  border: 0 none !important;
  color: $body-color;
}
.selected-values{
  .select__option{
    display: flex!important;
    width: auto;
    background-color: transparent !important;
    padding: 0;
  }
}

.select__control--is-disabled {
  .select__indicators {
    display: none;
  }
  .select__single-value {
    color: $body-color;
  }
}
.dropdown-toggle:disabled {
    opacity: 1;

    .badge {
      i {
        display: none;
      }
    }

    &:after {
      display: none;
    }
}

// React Select
.select__control {
  border-color: $light-blue !important;
}
.select__indicator-separator {
  background: transparent !important;
}
.select__menu {
  .select__group {
    padding: 0 !important;
  }
}

//Datepicker
.react-datepicker {
  border: 1px solid #f1f4f8;
  box-shadow: 0px 10px 15px $shadow;

  .react-datepicker__triangle {

    .react-datepicker-popper[data-placement^="top"] & {
      border-top-color: #fff;
      margin-top: -7px;
      &::before {
        border-top-color: #f1f4f8;
      }
    }

    .react-datepicker-popper[data-placement^="bottom"] & {
      border-bottom-color: #fff;
      margin-bottom: -7px;
      &::before {
        border-bottom-color: #f1f4f8;
      }
    }

  }

  .react-datepicker__header {
    background: #fff;
    border: 0 none;

    .react-datepicker__year-read-view {
      font-size: 1rem;
      font-weight: 900;
      color: theme-color("primary");

      .react-datepicker__year-read-view--down-arrow {
        &::before {
          border-top-color: theme-color("light");
        }
      }
    }

    .react-datepicker__current-month,
    .react-datepicker__day-name  {
      color: theme-color("primary");
    }

    .react-datepicker__navigation--previous {
      border-right-color: #fff;
    }

    .datepicker__navigation--next {
      border-left-color: #fff;
    }
  }
}

.user-form {
  .file-uploader {
    margin-left: 1rem;
    margin-bottom: 0;
  }
}

.form-check-input{
  width: 20px;
  height: 20px;
}
