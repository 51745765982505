// Table
table.table {
  font-size: 0.75rem;
  margin-bottom: 0px;

  .badge {
    font-size: 10px;
  }

  thead {
    th {
      font-size: 0.75rem;
      font-weight: 800;
      vertical-align: middle;
      color: theme-color('secondary');
      text-transform: uppercase;
      border-top: 0 none;
    }
  }

  td {
    vertical-align: middle;
  }

  .user-card {
    .avatar {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .user-info {
      h5 {
        font-size: 0.75rem;
      }
    }
  }
}

// Datatable
.datatable {
  .datatable-top {
    display: block;

    .datatable-top-inner {
      display: flex;
    }

    &.fixed-top {
      position: fixed;
      background: $body-bg;
      color: theme-color('secondary');
      align-items: center;
      padding-top: 30px;
      margin-bottom: 30px;
      padding: 5px;
      right: 0;
      left: 0;
      top: 80px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      padding-right: 20px;
      min-width: 320px;
      z-index: 10;
      box-shadow: 0 5px 10px $shadow;

      .datatable-top-inner {
        @extend .container;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 40px;
        padding-right: 40px;
      }

      .page-title {
        align-items: center;
        margin: 0;

        h1 {
          font-size: 1.3rem;
          padding: 8px 0;
          color: theme-color('secondary');
        }

        .dropdown {
          button {
            font-size: 1rem;
            color: theme-color('secondary');
            border-color: theme-color('secondary');
            padding: 2px 5px;

            &:hover,
            &:focus {
              color: #fff;
              background: theme-color('light');
              border-color: theme-color('light');
              box-shadow: none;
            }
          }
        }
      }

      .actions {

        button.btn,
        a.btn {
          font-size: 1.3rem;
          color: theme-color('secondary');

          &:hover,
          &:focus {
            color: #fff;
            background-color: theme-color('light');
            box-shadow: none;
          }
        }
      }

      .datatable-actions {
        margin-left: auto;

        .show-columns {
          display: inline-block;
          margin-left: auto;

          button {
            text-transform: uppercase;
            font-weight: 800;
          }
        }
      }
    }
  }

  .datatable-header {
    .show-columns {
      margin-left: auto;

      button {
        color: theme-color('light');
        padding: 2px;
        outline: 0;
      }
    }
  }

  .datatable-stats {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: theme-color('light');

    .stat {
      &:after {
        content: '|';
        padding-left: 5px;
        padding-right: 5px;
      }

      &:last-child {
        &::after {
          content: '';
          padding: 0;
        }
      }
    }
  }

  .sticky-thead {
    position: relative;
  }

  .datatable-sticky-thead {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;

    table.table {
      background-color: $gray-100;
      margin: 0 !important;
      padding-top: 0 !important;
    }
  }

  .datatable-filters {
    display: flex;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    align-items: center;
    box-shadow: 0 10px 15px $shadow;
    margin-bottom: 30px;
    padding: 15px;

    h3 {
      font-size: 1rem;
      color: theme-color('light');
      margin-bottom: 0;
    }

    .filter-badge {
      cursor: pointer;

      &:hover {
        background-color: theme-color('primary') !important;
      }
    }

    .remove-all {
      font-size: 0.8rem;
      color: theme-color('primary');
      cursor: pointer;

      &:hover {
        color: theme-color('secondary');
      }
    }
  }

  .datatable-header {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  table.table {
    border-radius: 10px;
    font-size: 80%;
    padding-left: 10px;

    thead {
      th {
        cursor: pointer;
        border: 0;
        white-space: nowrap;
        padding-top: 0;
        padding-bottom: 0;

        span {
          display: inline-block;

          i {
            margin-left: 5px;
            color: theme-color('light');
          }
        }

        &.active {
          span {
            i {
              color: theme-color('primary');
            }
          }
        }
      }
    }

  }

  .swipe-indicator-wrapper {
    position: fixed;
    top: 50%;
    right: -30px;

    .swipe-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      background: #fff;
      transform: translateY(-50%) rotate(45deg);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
      padding-right: 20px;
      padding-top: 20px;
      z-index: 10;

      i {
        transform: rotate(-45deg);
      }
    }
  }
}

// Card table
table.card {
  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray-100;

    td {
      vertical-align: middle;
      border-top: 0 none;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .table-label {
    font-size: 14px;
    font-weight: 900;
    color: theme-color('primary');
  }

  .table-actions {
    button {
      font-size: 14px;
      border: 0 none;
      background: transparent;
      box-shadow: none;
      outline: 0;
    }

    i {
      color: theme-color('light');
      font-size: 1.5em;
      padding: 5px;
    }
  }
}

.table-actions {
  display: flex;
  min-width: 60px;

  a {
    color: $light !important;
    font-size: 1.25rem;
    padding: 2px 5px;

    &:hover {
      color: $secondary !important;
    }
  }
}

// datatable columns
.datatable {
  th, td {
    &.item-date {
      width: 130px;
    }
  }
}