.modal-content {
  border: 0 none;
  box-shadow: 0 15px 30px $shadow;

  .modal-header {
    position: relative;
    border-bottom: 0 none;
    justify-content: center;

    .modal-title {
      margin: auto;
      font-weight: 900;
    }

    .close {
      position: absolute;
      right: 15px;
      color: theme-color("light");
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    border-top: 0 none;
  }
}
