.btn {
  font-weight: 900;

  &.btn-light {
    background: $gray-100;
    border: 0 none;
    color: theme-color("light");
  }

  &.btn-text-small{
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    height: 36px;
  }
}

.nav-pills {
  .nav-link{
    color: theme-color("secondary");
    &.active {
      color: #fff;
      background-color: theme-color("secondary");
    }
  }
}
