body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-lighter {
  background-color: #f9f9f9!important;
}

video {
  max-width: 100%;
}

.app {
  min-height: 100vh;
  overflow: hidden;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h2 {
    font-weight: 300;
  }

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.active {
      font-weight: bold;
    }
  }

  .app-content {
    min-height: 100vh;
    padding-left: 0;
    background-color: $gray-100;
    min-width: $app-sidebar-width;

    @include media-breakpoint-up(lg) {
      .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  // Offcanvas
  &.offcanvas {
    .app-content {
      margin-left: $app-sidebar-width;
    }

    .app-header,
    .page-header,
    .editor-header,
    .datatable-top {
      left: $app-sidebar-width !important;
    }

    .app-sidebar {
      transform: translateX(0);
    }
  }

  .app-header {
    position: fixed;
    background: #fff;
    padding: 15px 0;
    border-bottom: 1px solid $gray-200;
    z-index: 11;
    min-width: $app-sidebar-width; // Fix to not stack menu when offcanvas is showed

    .inner {
      display: flex;
      align-items: center;

      .searchbar {
        .input-group-text {
          font-size: 24px;
          background: transparent;
          border-radius: 0;
          border-left: 0;
          border-top: 0;
          border-right: 0;
          color: theme-color('light');
        }

        .form-control {
          font-size: 24px;
          font-weight: 300;
          color: theme-color('light');
          background: transparent;
          border-radius: 0;
          border-top: 0;
          border-right: 0;
          border-left: 0;
          outline: 0;

          &:focus {
            outline: 0;
          }
        }

        i {
          font-size: 15px;
        }
      }

      .notification-header {
        display: flex;
        margin-left: 15px;

        @include media-breakpoint-up(sm) {
          margin-left: auto;
        }

        button {
          font-size: 30px;
          padding: 5px;
          color: theme-color('light');
        }

        .dropdown-menu {
          font-size: 0.9rem;
          padding: 0;
          min-width: 200px;

          .dropdown-divider {
            margin: 0;
          }

          .notification-item {
            display: flex;
            padding: 0.5rem 1.5rem;
            color: theme-color('light');
            align-items: center;

            &:hover,
            &:focus {
              color: theme-color('secondary');
            }

            .notification-item-icon {
              margin-right: 5px;
            }

            .notification-item-link {
              margin-left: auto;
            }
          }
        }
      }

      .user-header {
        display: flex;
        margin-left: 15px;

        button.btn-link {
          padding: 0;
        }

        .dropdown-menu {
          button.nav-link,
          button.btn-link {
            border: 0 none;
            background: transparent;
            color: theme-color('primary');
            width: 100%;
            text-align: left;
            font-weight: normal;
            font-size: 15px;
            padding: 0.5rem 1rem;

            &:hover,
            &:focus {
              color: theme-color('secondary');
            }
          }
        }

        .user-card {
          @include media-breakpoint-down(sm) {
            min-width: auto;
          }

          .avatar {
            @include media-breakpoint-down(sm) {
              height: 40px;
              width: 40px;
            }

            img {
              @include media-breakpoint-down(sm) {
                height: 40px;
                width: 40px;
              }
            }
          }

          .user-info {
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }
    }
  }

  .app-sidebar {
    background-color: #fff;
    box-shadow: 0px 15px 30px $shadow;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 320px;
    z-index: 100;
    transform: translateX(-320px);

    .app-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      top: 30px;
      text-align: center;

      @include media-breakpoint-up(sm) {
        width: 220px;
        top: 60px;
      }

      figure {
        img {
          width: 100%;
          max-width: 160px;
        }
      }
    }

    .nav {
      .nav-link,
      .btn-link {
        border-left: 15px solid;
        border-right: 0;
        border-color: transparent;
        padding: 0.5rem 1rem;
        padding-left: 30px;
        font-size: 18px;
        font-weight: 600;
        color: $secondary;
        transition: all 0.4s ease;
        width: 100%;
        text-align: left;
        outline: 0;
        border-top: 0;
        border-bottom: 0;
        background: transparent;
        border-radius: 0;

        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }

        &.active {
          border-left: 15px solid;
          border-color: theme-color('primary');
          color: theme-color('primary');
        }

        i {
          min-width: 40px;

          @include media-breakpoint-up(sm) {
            font-size: 22px;
          }
        }
      }
    }
  }

  .login {
    display: flex;
    position: relative;
    min-height: 100vh;
    background-image: url('../../imgs/alpi-training-bg.jpg');
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($body-bg, 0.94);
    }

    & > div {
      position: relative;
      z-index: 2;
    }

    .welcome {
      @include media-breakpoint-down(sm) {
        margin: auto;
      }

      h1 {
        color: #fff;
        text-shadow: 0px 2px 3px rgba(#000, 0.3);

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .credits {
        font-size: 14px;
        color: #fff;

        img {
          height: 17px;
          width: auto;
          margin-left: 5px;
        }
      }
    }

    .loginbar {
      position: relative;
      border-radius: $box-radius;
      background-color: #fff !important;
      box-shadow: $box-shadow;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      max-width: 320px;
      z-index: 10;

      // @include media-breakpoint-down(sm) {
      padding: 30px;
      margin: auto;
      min-width: auto;
      min-height: auto;
      height: auto;
      // }

      .app-logo {
        width: 220px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        figure {
          img {
            margin-bottom: 15px;
          }

          figcaption {
            font-size: 24px;
            color: theme-color('secondary');
          }
        }
      }

      .alert-danger {
        color: #fff;
        font-size: 0.75rem;
      }
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// Required
.required {
  color: $warning;
  font-weight: 900;
}

// Page not found
.page-not-found {
  display: flex;
  position: relative;
  min-height: 100vh;
  background-image: url('../../imgs/alpi-training-bg.jpg');
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($gray-100, 0.7);
  }

  .inner {
    position: relative;
    text-align: center;
    width: 320px;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 15px 30px $shadow;
    z-index: 1;

    h1 {
      font-size: 4rem;

      span {
        display: block;
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}

.react-datepicker-wrapper {
  display: block;

  input[type='text'] {
    @extend .form-control;
  }
}

.badge {
  color: #fff;
  text-transform: uppercase;
  font-size: 80%;
  padding: 3px 5px;

  &.confirmed {
    @extend .btn-success;
  }

  &.pending {
    @extend .btn-warning;
    color: #fff;
  }

  &.cancelled {
    @extend .btn-danger;
  }
}

.alert {
  border: 0 none;

  .alert-success {
    color: #fff;
    background: theme-color('success');
  }

  .alert-danger {
    color: #fff;
    background: theme-color('danger');
  }
}

.card {
  border-radius: 10px;
  background: #fff;
  border: 0 none;
  box-shadow: $box-shadow;
  // overflow: hidden;

  .card-header {
    border-radius: 10px 10px 0 0;
    background: #fff;
    padding: 20px;
    border: 0 none;
    font-weight: 700;
    color: theme-color('secondary');
    border-bottom: 1px solid $gray-100;

    &.standalone {
      border-radius: 10px;
      border-bottom: 0 none;
    }
  }

  .card-body {
    .card-title {
      font-size: 15px;
      text-transform: uppercase;
      color: theme-color('dark');
    }
  }
}

.media-item {
  .card {
    border: 4px solid transparent;
  }
}

.logo-upload {
  border: 1px solid $light-blue;
  box-shadow: none;
  padding: 15px;

  .actions {
    min-width: auto;

    .card-link {
      color: theme-color('primary');
      cursor: pointer;

      &:hover {
        color: theme-color('secondary');
      }
    }
  }

  .card-img-top {
    max-width: 200px;
    margin: auto;
    height: auto;
  }
}

.widget {
  position: relative;
  background: $white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: $box-shadow;
}

.stat-resume {
  @extend .widget; // remove after adding widget class instead of bg-light on stat-resume

  margin-bottom: 30px;
  font-size: 1rem;
  background-color: #fff;

  &.athletes {
    background-color: $primary;

    .lead {
      color: $white;
    }

    h3 {
      color: $white;
    }

    .icon-wrapper {
      color: rgba($white, 0.12);
    }
  }

  .lead {
    font-size: 4rem;
    font-weight: 700;
    color: $black;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 700;
    color: $black;
  }

  .icon-wrapper {
    position: absolute;
    font-size: 8rem;
    line-height: 8rem;
    color: rgba($black, 0.12);
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    bottom: 0;
    padding-right: 30px;
    z-index: 1;
  }
}

.avatar {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 50px;
    height: 50px;
  }

  &.avatar-sm {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.user-card {
  min-width: 200px;

  &.disabled {
    opacity: 0.3;
  }

  .user-info {
    margin-left: 15px;
    text-align: left;

    h5 {
      font-size: 16px;
      text-decoration: none;
    }

    .user-role {
      font-size: 12px;
      text-decoration: none;
      color: theme-color('light');
    }
  }
}

.actions {
  text-align: right;
  min-width: 170px;

  .btn {
    font-size: 20px;
    padding: 3px 5px;
    color: theme-color('light');
    background: transparent;
    border: 0 none;

    @include media-breakpoint-up(sm) {
      font-size: 30px;
      padding: 5px 8px;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background: transparent;
    }
  }
}

// Burger Menu
.nav-toggle {
  background: transparent;
  margin-right: 15px;
  border: 0 none;
  text-decoration: none;
  height: 44px;
  width: 44px;
  border-bottom: none !important;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    border-bottom: none !important;
  }

  i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 3px;
    text-transform: uppercase;
    text-indent: -55px;
    background: theme-color('light');
    transition: all 0.2s ease-out;

    &::before,
    &::after {
      content: '';
      width: 25px;
      height: 3px;
      background: theme-color('light');
      position: absolute;
      left: 0;
      transition: all 0.2s ease-out;
    }
  }

  i::before {
    top: -7px;
  }

  i::after {
    bottom: -7px;
  }

  &.active {
    i {
      background: transparent;

      &::before {
        background: theme-color('light');
        top: 0;
        transform: rotateZ(45deg);
      }

      &::after {
        background: theme-color('light');
        bottom: 0;
        transform: rotateZ(-45deg);
      }
    }
  }
}

.file-explorer {
  font-size: 0.8rem;

  thead {
    th {
      border-top: 0 none;
    }
  }

  tbody {
    tr {
      &:hover {
        background: $gray-100;
      }

      td {
        padding: 0.5rem;
        vertical-align: middle;

        .file-explorer-item-actions {
          min-width: 50px;
          margin-bottom: 0;
          text-align: right;

          .btn-link {
            color: theme-color('light');

            &:hover,
            &:focus {
              color: theme-color('primary');
            }
          }
        }
      }
    }
  }
}

// File uploader
.file-uploader {
  border-style: dashed !important;
  border-color: $light-blue !important;
  cursor: grab;
  border-radius: 0.25rem;
  margin-bottom: 15px;

  > div {
    padding: 20px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul.file-uploader-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li {
      display: flex;
      justify-content: space-between;
      padding: 5px 15px;
      cursor: pointer;

      &:nth-child(odd) {
        background: lighten($light-blue, 15%);
      }

      div {
        margin-right: auto;
      }
    }
  }
}

// Dropdowns
a.btn-link,
button.btn-link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}

// Modal
.modal-content {
  // Add a class to each modal (ex.: export-modal and filter-modal)
  .modal-body {
    padding: 30px;
  }

  .form-inline {
    margin-bottom: 15px;

    > label {
      justify-content: left;
      min-width: 150px;
      font-weight: 800;
      font-size: 1rem;
      color: theme-color('secondary');
    }

    .form-check {
      margin-right: 15px;
    }
  }
}

// Loader
.loading {
  min-height: 200px;

  .loader {
    .line-scale-pulse-out > div {
      background-color: theme-color('secondary');
    }
  }

  &.loading-mini {
    min-height: 80px;
  }
}

// Flags
.country {
  align-items: center;

  .code {
    margin-left: 5px;
  }

  .flag {
    .flag-icon {
      box-shadow: 2px 2px 3px $shadow;
    }
  }
}

// Floatings buttons
.floating-btn {
  position: fixed;
  display: flex;
  right: 30px;
  bottom: 30px;
}

.back-to-top,
.go-to-date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 50px;
  height: 50px;
  color: $white;
  border-radius: 50%;
  box-shadow: 2px 2px 10px $shadow;
}

.back-to-top {
  background: theme-color('primary');
}

.go-to-date {
  background: theme-color('secondary');
}

// Drag scroll curser
.indiana-scroll-container--dragging {
  cursor: grabbing !important;
}

// Icons set
i {
  &.icon-swipe {
    display: inline-block;
    vertical-align: middle;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.0' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 397.3 397.3' style='enable-background:new 0 0 397.3 397.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23004272;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath className='st0' d='M287.3,222.1c-1-6.4-4-12.5-8.8-17.4c-7.1-7.1-16.6-10-25.8-9l49.8-49.8c12.4-12.4,12.4-32.8,0-45.2 c-12.4-12.4-32.8-12.4-45.2,0L149.5,208.3l0-16c0-17.6-7.2-33.6-18.7-45.2c-8.4-8.4-19.1-14.4-31-17.1c-0.8-0.2-1.7-0.3-2.6-0.3 c-6.4,0-11.6,5.2-11.6,11.6l0,152.4c0,0,0,0.1,0,0.1v2.5l0.1-0.1c0.6,25.7,10.6,51.2,30.2,70.8c40.5,40.5,106,40.5,146.5,0 l37.2-37.2c8.3-8.3,11-20.1,8.2-30.8L287.3,222.1z M288.3,318.4l-37.2,37.2c-34.2,34.2-89.7,34.2-123.9,0 c-15.9-15.9-25-37.2-25.5-59.8l-0.1-148.8c6.7,2.4,12.8,6.2,17.9,11.3c9.1,9.1,14,21.1,14,33.9v29.6c0,2.8,1.6,5.3,4.1,6.4 c5.9,2.6,12.9,1.6,17.7-3.2l113.1-113.1c6.2-6.2,16.4-6.2,22.6,0c6.2,6.2,6.2,16.4,0,22.6l-65.6,65.6c-2.4,2.4-3,6-1.6,9.1 c1.5,3.1,4.7,4.9,8,4.5l22.4-2.3c2.9-0.3,8.3-0.1,12.8,4.4c2.9,2.9,4,6.3,4.4,8.7l20.8,78.4C293.8,308.7,292.3,314.4,288.3,318.4z '/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpolygon className='st0' points='181.3,0 170,11.3 198.9,40.3 117.5,40.3 117.5,56.3 198.9,56.3 170,85.2 181.3,96.5 229.5,48.3 '/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    width: 25px;
    height: 25px;

    &.neg {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.0' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 397.3 397.3' style='enable-background:new 0 0 397.3 397.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cg%3E%3Cpath className='st0' d='M287.3,222.1c-1-6.4-4-12.5-8.8-17.4c-7.1-7.1-16.6-10-25.8-9l49.8-49.8c12.4-12.4,12.4-32.8,0-45.2 c-12.4-12.4-32.8-12.4-45.2,0L149.5,208.3l0-16c0-17.6-7.2-33.6-18.7-45.2c-8.4-8.4-19.1-14.4-31-17.1c-0.8-0.2-1.7-0.3-2.6-0.3 c-6.4,0-11.6,5.2-11.6,11.6l0,152.4c0,0,0,0.1,0,0.1v2.5l0.1-0.1c0.6,25.7,10.6,51.2,30.2,70.8c40.5,40.5,106,40.5,146.5,0 l37.2-37.2c8.3-8.3,11-20.1,8.2-30.8L287.3,222.1z M288.3,318.4l-37.2,37.2c-34.2,34.2-89.7,34.2-123.9,0 c-15.9-15.9-25-37.2-25.5-59.8l-0.1-148.8c6.7,2.4,12.8,6.2,17.9,11.3c9.1,9.1,14,21.1,14,33.9v29.6c0,2.8,1.6,5.3,4.1,6.4 c5.9,2.6,12.9,1.6,17.7-3.2l113.1-113.1c6.2-6.2,16.4-6.2,22.6,0c6.2,6.2,6.2,16.4,0,22.6l-65.6,65.6c-2.4,2.4-3,6-1.6,9.1 c1.5,3.1,4.7,4.9,8,4.5l22.4-2.3c2.9-0.3,8.3-0.1,12.8,4.4c2.9,2.9,4,6.3,4.4,8.7l20.8,78.4C293.8,308.7,292.3,314.4,288.3,318.4z '/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpolygon className='st0' points='181.3,0 170,11.3 198.9,40.3 117.5,40.3 117.5,56.3 198.9,56.3 170,85.2 181.3,96.5 229.5,48.3 '/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

// editorjs
// .ce-block__content {
//   max-width: 80% !important;
// }

ul.specs {
  padding-top: 10px;
  font-size: 14px;
}

.bg-dark{
  label{
    color: #fff;
  }
  input.form-control{
    color: #fff;
    background-color: #333;
    &:disabled{
      color: #fff;
      background-color: transparent;
    }
  }
  .select__control,
  .select__control--menu-is-open,
  .select__menu{
    background-color: #333;
  }
  .select__option{
    color: #fff;
  }
  .select__option--is-focused{
    background-color: #000;
  }
  .select__multi-value,
  .select__multi-value__label,
  .select__multi-value__remove{
    color: #fff;
    background-color: #000;
  }
}
