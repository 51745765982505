.page {
  padding-top: 160px;
  padding-bottom: 60px;

  // .page-container {
  //   background-color: $body-bg;
  //   border-radius: $box-radius;
  //   padding-top: 30px;
  //   padding-bottom: 15px;
  //   box-shadow: $box-shadow;
  // }

  .page-header {
    display: flex;
    position: fixed;
    background: #fff;
    color: theme-color('primary');
    align-items: center;
    padding: 5px;
    left: 0;
    right: 0;
    top: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 320px;
    z-index: 10;
    box-shadow: 0 5px 10px $shadow;

    @include media-breakpoint-up(lg) {
      padding-left: 40px;
      padding-right: 40px;
    }

    .page-title {
      display: flex;
      align-items: center;

      h1 {
        font-size: 1.3rem;
        margin-bottom: 0;
        padding: 10px 0;
        color: theme-color('primary');
      }
    }

    .actions {
      margin-left: auto;

      button,
      a.btn {
        font-size: 1.3rem;
        color: theme-color('primary');

        &:hover,
        &:focus {
          color: #fff;
          background: theme-color('light');
        }
      }
    }
  }

  // Editors
  .editor {
    .editor-header {
      display: flex;
      position: fixed;
      background: #fff;
      color: theme-color('primary');
      align-items: center;
      padding: 5px;
      left: 0;
      right: 0;
      top: 80px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 20px;
      padding-right: 20px;
      min-width: 320px;
      z-index: 10;
      box-shadow: 0 5px 10px $shadow;

      @include media-breakpoint-up(lg) {
        padding-left: 40px;
        padding-right: 40px;
      }

      .editor-title {
        display: flex;
        align-items: center;
        margin: 0;
        flex: 1;

        h1 {
          font-size: 1rem;
          color: theme-color('secondary');
        }

        .input-togglable {
          width: 100%;
        }

        .form-control {
          color: theme-color('secondary');
        }

        .dropdown {
          button {
            font-size: 1rem;
            color: theme-color('secondary');
            border-color: theme-color('secondary');
            padding: 2px 5px;
          }
        }
      }

      .actions {
        margin-left: auto;

        button,
        a.btn {
          font-size: 1.3rem;
          color: theme-color('secondary');
          box-shadow: none;

          &:hover,
          &:focus {
            background: theme-color('light');
            color: #fff;
            box-shadow: none;
          }
        }
      }
    }

    .notification-wrapper{
      position: fixed;
      width: 200px;
      bottom: 80px;
      right:15px;
      z-index: 999;

      .notification {
        text-align: left;
        padding: 15px 30px;
        font-size: 1rem;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        color: #fff;
        box-shadow: 0 10px 20px $shadow;
      }
    }
    
    
  }

  // Headings
  .page-title,
  .editor-title {

    a.active,
    button.back-button,
    span.back-button {
      font-size: 1rem;
      margin-right: 15px;
      border: 0 none;
      background: transparent;
      padding: 5px;
      color: theme-color('primary');
    }

    h1 {
      margin-bottom: 0;
    }

    .dropdown {
      button {
        @include media-breakpoint-down(sm) {
          font-size: 15px;
          padding: 5px 8px;
        }
      }
    }
  }
}

.dropdown-menu {
  font-size: 15px;
  border: 0 none;
  padding: 15px;
  box-shadow: 0 10px 15px $shadow;
}

// Move this input in editor-title
.input-togglable .form-control {
  font-size: 1rem;
  font-weight: 700;
  color: theme-color('secondary');
  background: transparent;
  border-color: $light-blue;
}

#comments {
  .comment {
    align-items: flex-start;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-100;

    &:last-child {
      border-bottom: 0 none;
    }

    .user-card {
      min-width: auto;
      margin-right: 15px;
    }

    .comment-body {
      font-size: 15px;
      margin-top: 15px;
    }

    .comment-date {
      font-size: 12px;
      font-weight: 700;
      color: theme-color('light');
      min-width: 150px;
      text-align: right;
      margin-left: auto;
    }
  }
}

#reply {
  border-top: 1px solid $gray-100;
  padding: 15px 0;

  .avatar {
    min-width: 50px;
  }
}

.team {
  li {
    margin-bottom: 15px;

    .user-card {
      min-width: auto;
    }
  }
}

//page
.login {
  animation-delay: 0.5s;
  animation-duration: 1s;

  .login-form {
    input.form-control {
      &::placeholder {
        font-size: 0.85rem;
      }
    }
  }
}

.page {
  &.page-events {
    .date-year {
      display: none;
    }
  }
}

.workouts {
  display: flex;
  align-items: center;

  .workout {
    display: flex;
    padding: 2px;

    .workout-badge {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $light;
    }

    .workout-done {
      background: $primary;
    }
  }

  .current-day {
    background: #f4f5f6;
  }
}